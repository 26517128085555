import { useAppStore } from '@/store/useAppStore';
import { useOrganizationOnboardingStore } from '@/store/useOrganizationOnboardingStore';
import { useScreenerStore } from '@/store/useScreenerStore';
import { useUserOnboardingStore } from '@/store/useUserOnboardingStore';
import { redirect } from '@tanstack/react-router';
import axios from 'axios';

export type TCustomError = {
  message: string;
  code: number;
  name: string;
  payload: null;
};
export type TCustomAxiosError = {
  code: number;
  data: null;
  success: boolean;
  errors: TCustomError[];
};

export type TCustomAxiosResponse<T> = {
  code: number;
  success: boolean;
  errors: TCustomError[];
  data: T;
  pagination?: {
    page: number;
    limit: number;
    totalPages: number;
    offset: number;
    totalItems: number;
  };
};

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => response,
  (err) => {
    // TODO: Handle errors globally and token expiration
    if (err) {
      const error = err?.response?.data as TCustomAxiosError;
      // Handle specific error cases
      if (error?.code === 401) {
        const appStore = useAppStore.getState();
        const userOnboardingStore = useUserOnboardingStore.getState();
        const organizationOnboardingStore = useOrganizationOnboardingStore.getState();
        const screenerStore = useScreenerStore.getState();

        appStore.reset();
        userOnboardingStore.reset();
        organizationOnboardingStore.reset();
        screenerStore.reset();

        redirect({
          to: '/sign-in',
        });
        // Unauthorized
      } else if (error.code === 404) {
        // Not found
      }
      // NOTE: Make generic axios error more readable. This is just an example, remove if unnecessary.
      return Promise.reject(error);
    }
  },
);

export { api };
