import queryKeys from '@/constants/queryKeys';
import { organizationMembersForAdminGet } from '@/services/api/requests/organization';
import { TUser } from '@/types/User.types';
import { Box, Center, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppStore } from '@/store/useAppStore';
import CustomButton from '@/components/atoms/CustomButton';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import Loader from '@/components/atoms/Loader';
import OrganizationRosterDeleteMemberModal from './OrganizationRosterDeleteMemberModal';
import DeleteIcon from '@/components/atoms/DeleteIcon';
import { TRosterItemWithIdAndRole } from '@/types/Roster.types';
import iconsPng from '@/assets/img/png/icons';
import tableTheme from '@/theme/components/table';
import { TABLE_COLUMNS_ORG_ROSTER_VIEW } from '@/utils/tableConfigs';
import roles from '@/constants/roles';
import useRemoveMemberFromOrganization from '@/hooks/organization/removeMemberFromOrganization';
import OrganizationRosterAddMemberModal from './OrganizationRosterAddMemberModal';
import OrgMemberPendingInvite from './OrgMemberPendingReinvite';
import { usePagination } from '@/hooks/usePagination';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';

const WAIT_LOADING_MILLISECONDS = 700;

const OrgMembersRoster = () => {
  const theme = useTheme(tableTheme);
  const queryClient = useQueryClient();

  const { organization } = useAppStore();

  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false);

  const [itemToDeleteEmail, setItemToDeleteEmail] = useState('');
  const [itemToDeleteId, setItemToDeleteId] = useState('');

  const onClickDelete = useCallback((item: TRosterItemWithIdAndRole) => {
    setDeleteConfirmationModalVisible(true);
    setItemToDeleteId(item.id);
    setItemToDeleteEmail(item.Email);
  }, []);

  const tableColumns = useMemo(() => {
    const columns: {
      label: string | JSX.Element;
      renderCell: (item: TRosterItemWithIdAndRole) => string | JSX.Element;
    }[] = TABLE_COLUMNS_ORG_ROSTER_VIEW;

    if (columns.length === 5) {
      columns.push({
        label: <Flex maxWidth={'100px'}>DELETE</Flex>,
        renderCell: () => '',
      });
    }
    return columns;
  }, [onClickDelete]);

  const {
    isLoading: isOrgMembersLoading,
    data: orgMembers,
    refetch: refetchOrgMembers,
    isRefetching: isFetchingOrgMembers,
    pagination,
  } = usePagination<TUser[]>({
    query: organizationMembersForAdminGet,
    queryKey: queryKeys.organization.members,
  });

  const [isWaitLoading, setIsWaitLoading] = useState(false); // improves UX by having the UI start the loader immediately after an action (add/delete member); this is needed because we have to wait 2 seconds to fetch data from auth0

  const isLoading = isOrgMembersLoading || isWaitLoading;

  const [rawData, setRawData] = useState([] as TRosterItemWithIdAndRole[]);

  const mapBeUserToTableUser = useCallback(
    (users: TUser[]): TRosterItemWithIdAndRole[] =>
      users.map((user) => ({
        'First Name': user.firstName ?? user.user_metadata.firstName,
        'Last Name': user.lastName ?? user.user_metadata.lastName,
        Phone: user.user_metadata.phone ?? '/',
        Email: user.email,
        Invitation:
          user.email_verified || user.roles?.includes(roles.orgAdmin) ? (
            'Accepted'
          ) : (
            <OrgMemberPendingInvite user={user} />
          ),
        id: user.user_id,
        isAdmin: user.roles?.includes(roles.orgAdmin),
      })),
    [],
  );

  useEffect(() => {
    if (orgMembers) {
      setRawData(mapBeUserToTableUser(orgMembers));
    }
  }, [orgMembers]);

  const data = useMemo(() => ({ nodes: rawData ?? [] }), [rawData]);

  const reFetch = useCallback(() => {
    // TODO: see how to improve this... remove the wishful 2s wait and waitLoading...
    setTimeout(() => setIsWaitLoading(true), WAIT_LOADING_MILLISECONDS);
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.organization.members], exact: false });
      setIsWaitLoading(false);
    }, 2000); // if we don't wait 2 seconds before re-fetching, newly added user doesn't appear
  }, [refetchOrgMembers]);

  const { mutate: removeOrgMember } = useRemoveMemberFromOrganization(reFetch);

  const deleteMember = useCallback(
    (memberId: string) => {
      setTimeout(() => setIsWaitLoading(true), WAIT_LOADING_MILLISECONDS);
      removeOrgMember(memberId);
    },
    [removeOrgMember],
  );

  return (
    <Center marginTop={'30px'} paddingBottom={'10vh'}>
      <VStack width={MAX_CONTAINER_WIDTH} gap={0}>
        <HStack width={'100%'} justifyContent={'space-between'} alignItems={'stretch'}>
          <Text variant={'loraTitle'}>{organization?.name}&apos;s Roster</Text>

          <CustomButton
            width={'150px'}
            height={'55px'}
            onClick={() => setAddMemberModalVisible(true)}
            backgroundColor="primary.500"
            labelColor="extra.white"
            label="Add Member"
          />
        </HStack>

        {isLoading ? (
          <Center height={'860px'} paddingBottom={'20vh'}>
            <Loader />
          </Center>
        ) : (
          <VStack>
            <Box marginTop={'60px'}>
              {data.nodes.length > 0 ? (
                <>
                  <Table data={data} theme={theme}>
                    {(tableDataItems: TRosterItemWithIdAndRole[]) => (
                      <>
                        <Header>
                          <HeaderRow>
                            {tableColumns.map((column) => (
                              <HeaderCell key={column.label.toString()}>{column.label}</HeaderCell>
                            ))}
                          </HeaderRow>
                        </Header>

                        <Body>
                          {tableDataItems.map((item) => {
                            return (
                              <Row key={item.id} item={item}>
                                <Cell>{item['First Name']}</Cell>
                                <Cell>{item['Last Name']}</Cell>
                                <Cell>{item.Phone}</Cell>
                                <Cell>{item.Email}</Cell>
                                <Cell>{item.Invitation}</Cell>
                                {/* Add in the delete button for regular users or "Admin" text for admins */}
                                <Cell>
                                  {!item.isAdmin ? (
                                    <DeleteIcon onClick={() => onClickDelete(item)} centered={false} />
                                  ) : (
                                    <Text variant={'urbanistBold'}>Admin</Text>
                                  )}
                                </Cell>
                              </Row>
                            );
                          })}
                        </Body>
                      </>
                    )}
                  </Table>
                  <CompactTablePagination pagination={pagination} isFetchingNextPage={isFetchingOrgMembers} />
                </>
              ) : (
                <VStack
                  paddingY={'60px'}
                  justifyContent={'center'}
                  backgroundColor={'extra.white'}
                  borderRadius={'10px'}
                  width={MAX_CONTAINER_WIDTH}
                >
                  <Image alt="notepad" src={iconsPng.notepad} width={120} height={120} />
                  <Text variant={'urbanistSemiBoldSmallTitle'} color={'text.mediumGray'} marginTop={'30px'}>
                    Start by inviting/adding members to roster.
                  </Text>
                </VStack>
              )}
            </Box>
          </VStack>
        )}

        {/* Add Member modal */}
        <OrganizationRosterAddMemberModal
          addMemberModalVisible={addMemberModalVisible}
          setAddMemberModalVisible={setAddMemberModalVisible}
          reFetch={reFetch}
        />

        <OrganizationRosterDeleteMemberModal
          deleteConfirmationModalVisible={deleteConfirmationModalVisible}
          setDeleteConfirmationModalVisible={setDeleteConfirmationModalVisible}
          deleteItem={deleteMember}
          itemToDeleteId={itemToDeleteId}
          itemToDeleteEmail={itemToDeleteEmail}
        />
      </VStack>
    </Center>
  );
};

export default OrgMembersRoster;
