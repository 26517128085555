const rootPath = '/img/png/icons';

const iconsPng = {
  eyeClosed: `${rootPath}/eye-closed.png`,
  eyeOpened: `${rootPath}/eye-opened.png`,
  lock: `${rootPath}/lock.png`,
  info: `${rootPath}/info.png`,
  redX: `${rootPath}/red-x.png`,
  addMember: `${rootPath}/add-member.png`,
  okHand: `${rootPath}/ok-hand.png`,
  notepad: `${rootPath}/notepad.png`,
  pen: `${rootPath}/pen.png`,
  sliderCircleGrey: `${rootPath}/slider-circle-circled.png`,
  sliderCircleUntouched: `${rootPath}/slider-circle-untouched.png`,
  orangeExclamationMark: `${rootPath}/orange-exclamation.png`,
};

export default iconsPng;
